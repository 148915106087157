import { faParkingCircle } from "@fortawesome/pro-regular-svg-icons"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Circle, GoogleMap, LoadScript, Marker } from "@react-google-maps/api"
import admissionsParkingStyle from "assets/jss/material-kit-pro-react/views/admissionsPageSections/admissionsParkingStyle.jsx"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import InfoArea from "components/InfoArea/InfoArea.jsx"
import React from "react"
// react libraries
import Fade from "react-reveal/Fade"
import Slide from "react-reveal/Slide"

const useStyles = makeStyles(admissionsParkingStyle)
const mapsSelector = () => {
  if (
    /* if we're on iOS, open in Apple Maps */
    navigator.platform.indexOf("iPhone") !== -1 ||
    navigator.platform.indexOf("iPad") !== -1 ||
    navigator.platform.indexOf("iPod") !== -1
  )
    window.open(
      "https://maps.apple.com/?address=99%20N%20Henry%20St,%20Edgerton,%20WI%20%2053534,%20United%20States&auid=3125814345877632361&ll=42.834540,-89.069744&lsp=9902&q=Chilimania&_ext=ChoKBQgEEOIBCgQIBRADCgUIBhD5AgoECAoQABImKefjfV1DakVAMS7TbWLdRFbAOWW5o7lpa0VAQUbrM64URFbAUAQ%3D"
    )
  /* else use Google */ else
    window.open("https://www.google.com/maps/search/?api=1&query=chilimania")
}
export default function AdmissionsParking() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>Parking</h2>
          </GridItem>
        </GridContainer>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={12} md={5} className={classes.mlAuto}>
            <Fade delay={300}>
              <LoadScript googleMapsApiKey="AIzaSyAsY0jeNWYoaJu4PjsgiHiiNl9y7micsJA">
                <GoogleMap
                  mapContainerStyle={{ height: "400px" }}
                  center={{ lat: 42.834717, lng: -89.0697746 }}
                  zoom={15}
                >
                  <Circle
                    center={{ lat: 42.834717, lng: -89.0697746 }}
                    options={{ radius: 300 }}
                    onClick={mapsSelector}
                  />{" "}
                  <Marker
                    position={{ lat: 42.834717, lng: -89.0697746 }}
                    title={"Chilimania"}
                    onClick={mapsSelector}
                  />
                </GoogleMap>
              </LoadScript>
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={12} md={5} className={classes.mrAuto}>
            <Slide right delay={600}>
              <InfoArea
                className={classes.info4}
                title="Parking for Chilimania &amp; Country Edge"
                description="Free, on-street parking is available throughout the downtown area and on adjoining streets. Some local businesses offer paid parking on their property. Please be respectful of our business and residential neighbors while traveling to/from the festival grounds!"
                faIcon={faParkingCircle}
                iconColor="secondary"
              />
            </Slide>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
